var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.$route.meta.module)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          class: [props.row['tasks_total'] ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleChildRow(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "tasks"
          }
        })], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_c('PickListItemBottlingsTable', {
          ref: "item-bottlings",
          attrs: {
            "pick-list-id": _vm.pickListId,
            "parent-row": props.row,
            "read-only": _vm.readOnly
          },
          on: {
            "loaded": _vm.onChildTableLoad
          }
        })], 1);
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  }, [_vm.showAddButton ? _c('b-row', [_c('b-col', [_c('b-button-group', [_c('button', {
    staticClass: "btn btn-outline-dark btn",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-toolbar fa-plus"
  }), _vm._v(" Create ")])])], 1)], 1) : _vm._e()], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }